.portal-login-page {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background-color: #f5f9ff;
  background-image: url(https://smpimg-1258344701.file.myqcloud.com/dayu/dayu-static-resource/denglu08_1_compress.png);

  .login-card {
    position: relative;
    margin: 240px auto;
    padding-top: 80px;
    padding-bottom: 80px;
    width: 560px;
    text-align: center;
    overflow: hidden;
  }

  .banner {
    position: absolute;
    z-index: 10000;
    width: 100%;
    height: 60px;
    background: rgba(255, 255, 255, 0.3);
    top: 0px;
    display: flex;

    .logo {
      flex: 1;
      width: 120px;
      height: 36px;
      margin-top: 13px;
      margin-left: 18px;

      img {
        width: auto;
        height: 100%;
      }
    }

    .menus {
      flex: 11;
      text-align: right;

      a {
        display: inline-block;
        text-decoration: none;
        color: #4A5970;
        font-size: 14px;
        width: 100px;
        line-height: 60px;
        text-align: center;
      }
    }

    .helper-avatar {
      line-height: 60px;
      padding-right: 18px;
    }
  }
}
