html,
body,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  vertical-align: baseline;
}

pre {
  margin-bottom: 0 !important;
}

/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

body {
  width: 100%;
  height: 100%;
  position: relative;
  -webkit-text-size-adjust: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  line-height: 1;
  font-size: 14px;
  font-family: PingFangSC-Regular, SFHeitiSC, "microsoft yahei", "Helvetica Neue", Helvetica, STHeiTi, sans-serif !important;
  background: @background-color !important;
}

blockquote,
q {
  quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

/* custom */

a {
  color: @link-color;
  text-decoration: none;
  outline: 0;
  -webkit-backface-visibility: hidden;

  &:hover {
    color: @link-color;
  }
}

::-webkit-scrollbar {
  width: 3px;
  height: 6px;
}

::-webkit-scrollbar-track-piece {
  background-color: #F2F2F2;
  -webkit-border-radius: 6px;
}

::-webkit-scrollbar-thumb:vertical {
  height: 3px;
  background-color: #E5E5E5;
  -webkit-border-radius: 6px;
}

::-webkit-scrollbar-thumb:horizontal {
  width: 3px;
  background-color: #E5E5E5;
  -webkit-border-radius: 6px;
}

html {
  width: 100%;
  height: 100%;
  position: relative;
  /*overflow: hidden;*/
}

ul,
li {
  list-style-type: none;
}

/*tencent mobile reset css*/

*,
*:before,
*:after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

input::-webkit-input-placeholder {
  color: #999;
}

/*cancel input border radius*/
input,
textarea {
  -webkit-appearance: none;
  -webkit-border-radius: 0;
}

th {
  text-align: left;
}

.clearfix:after {
  content: "\200B";
  display: block;
  height: 0;
  clear: both;
}

.clearfix {
  *zoom: 1;
}

input:-internal-autofill-selected {
  color: #262626;
  background-color: #ffffff !important;
  background-image: none !important;
}

::-webkit-scrollbar {
  width: 8px;
}

input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0px 1000px transparent inset;
  transition: background-color 5000s ease-in-out 0s;
}

input:-webkit-autofill:focus {
  box-shadow: 0 0 0px 1000px transparent inset !important;
  -webkit-box-shadow: 0 0 0px 1000px transparent inset;
}

.ant-message-notice {
  .ant-message-notice-content {
    max-width: 500px !important;

    span {
      max-width: 500px !important;
      overflow-wrap: break-word;
    }
  }
}