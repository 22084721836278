.ant-spin-container {
  height: 100%;
}

.ant-spin-nested-loading {
  height: 100%;

  .ant-spin {
    height: 100% !important;
    max-height: inherit !important;
  }
}

.ant-input,
.ant-input-affix-wrapper {
  border-radius: 4px;
}

.ant-select:not(.ant-select-customize-input) .ant-select-selector,
.ant-picker {
  border-radius: 4px;
}

.ant-btn {
  border-radius: 4px;

  &.no-border-radius {
    border-radius: 0;
  }
}

.ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
  background: @primary-color;
}

.ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):focus-within {
  box-shadow: none;
}

.ant-select:not(.ant-select-customize-input) .ant-select-selector,
.ant-picker {
  border-radius: 4px;
}

.ant-table {
  .ant-table-row {
    a {
      color: #000000a6;
    }

    &:hover {
      a {
        color: @primary-color;
      }
    }
  }

  &.ant-table-small {
    font-size: 12px !important;

    .ant-badge-status-text {
      font-size: 12px;
    }

    .ant-table-title {
      padding-left: 0;
    }

    .ant-table-cell {
      padding: 8px;
      vertical-align: middle;
    }
  }

  &.ant-table-small .ant-table-tbody .ant-table-wrapper:only-child .ant-table {
    margin-left: 0;
  }

  .ant-table-tbody>tr>td {
    vertical-align: middle;
  }

  .ant-table-cell {
    vertical-align: middle;
  }

  .ant-table-summary {
    z-index: 1;
    background-color: #fafafa;

    tr>td {
      border: none;
      background-color: #fafafa;
      font-weight: bold;
    }
  }

  .ant-table-tbody>tr>td {
    padding: 8px 16px;
  }
}

.ant-typography {
  margin-bottom: 0 !important;
}

.ant-breadcrumb {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.ant-collapse-icon-position-right>.ant-collapse-item>.ant-collapse-header,
.ant-collapse-content>.ant-collapse-content-box {
  padding-left: 24px !important;
}

.ant-collapse>.ant-collapse-item>.ant-collapse-header {
  align-items: center;
}

.ant-form-item-extra {
  font-size: 12px;
  margin-top: 5px;
}

.ant-tree .ant-tree-treenode:not(.ant-tree .ant-tree-treenode-disabled).filter-node .ant-tree-title {
  font-weight: 600;
  color: @primary-color;
}

.ant-input-number-group-wrapper {
  vertical-align: middle;
}

.ant-modal-content {
  overflow: hidden;
  border-radius: 4px;
}

.ant-layout-sider {
  background-color: #ffffff !important;
}