.pl10 {
  padding-left: 10px !important;
}

.mb0 {
  margin-bottom: 0 !important;
}

.mb4 {
  margin-bottom: 4px !important;
}

.mb5 {
  margin-bottom: 5px !important;
}

.mb10 {
  margin-bottom: 10px !important;
}

.mb20 {
  margin-bottom: 20px !important;
}

.mt0 {
  margin-top: 0px !important;
}

.mt10 {
  margin-top: 10px !important;
}

.mt20 {
  margin-top: 20px !important;
}

.mt30 {
  margin-top: 30px !important;
}

.mr4 {
  margin-right: 4px !important;
}

.mr10 {
  margin-right: 10px !important;
}

.mr20 {
  margin-right: 20px !important;
}

.ml4 {
  margin-left: 4px !important;
}

.ml10 {
  margin-left: 10px !important;
}

.ml30 {
  margin-left: 30px !important;
}

.width-auto {
  width: 100%;
}

.width40 {
  width: 40px !important;
}

.width50 {
  width: 50px !important;
}

.width60 {
  width: 60px !important;
}

.width80 {
  width: 80px !important;
}

.width100 {
  width: 100px !important;
}

.width110 {
  width: 110px !important;
}

.width120 {
  width: 120px !important;
}

.width150 {
  width: 150px !important;
}

.width160 {
  width: 160px !important;
}

.width200 {
  width: 200px !important;
}

.width240 {
  width: 240px !important;
}

.width280 {
  width: 280px !important;
}

.width300 {
  width: 300px !important;
}

.width320 {
  width: 320px !important;
}

.width400 {
  width: 400px !important;
}

.width500 {
  width: 500px !important;
}

.width800 {
  width: 800px !important;
}

.width1342 {
  width: 1342px;
}

.fs12 {
  font-size: 12px;
}

.fs13 {
  font-size: 13px;
}

.fs16 {
  font-size: 16px;
}

.fs24 {
  font-size: 24px;
}

.container {
  padding: 12px;
  margin: 0 auto;
}

.inline-block {
  display: inline-block;
}

.flex {
  display: flex;
  align-items: center;
}

.align-items-center {
  align-items: center;
}

.flex-column {
  flex-direction: column;
}

.flex-align-center {
  align-items: center;
  justify-content: center;
}

.flex-end {
  justify-content: end;
}

.flex-align-baseline {
  align-items: baseline !important;
}

.flex-wrap {
  flex-wrap: wrap;
}

.break-all {
  word-break: break-all;
}

.white-space-normal {
  white-space: normal;
}

.space-between {
  justify-content: space-between;
}

.weight-number {
  font-family: DIN Alternate;
  font-style: normal;
  font-weight: bold;
  font-size: 32px;
  line-height: 48px;
  color: #000000;
}

.gray-border {
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 4px;
}

.pointer {
  cursor: pointer;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.text-center {
  text-align: center;
}

.color-gray {
  color: #a0a0a0 !important;

  &:hover {
    color: #a0a0a0 !important;
  }
}

.red,
.error-color {
  color: @error-color !important;
}

.blue,
.primary-color {
  color: @primary-color !important;
}

.orange,
.warning-color {
  color: @warning-color !important;
}

.green,
.success-color {
  color: @success-color !important;
}

.disabled-color {
  color: @disabled-color !important;
}

.hover-color {
  &:hover {
    color: @primary-color !important;
  }
}

.text-overflow {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.text-ellipsis-2 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.text-ellipsis-3 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

.text-ellipsis-4 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
}

.overflow-y-scroll {
  overflow-y: scroll;
}

.bold {
  font-family: DINAlternate-Bold;
  font-weight: bold;
}

.cursor {
  cursor: pointer;
}

.no-allowed {
  color: rgba(0, 0, 0, 0.5) !important;
  cursor: not-allowed;
}

.pointer-not-allowed {
  cursor: not-allowed;
}

.pointer-move {
  cursor: move;
}

.hover-dark {
  &:hover {
    background-color: @border-color-base;
  }
}

.hover-gray {
  &:hover {
    background-color: #f7f9fc;
  }
}

.overflow-y-scroll {
  overflow-y: scroll;
}

.upload-picture-card {
  .ant-upload.ant-upload-select-picture-card {
    width: 120px;
    height: 120px;
  }

  .upload-picture-card-text {
    font-size: 11px;
    color: @text-color-gray;
  }
}

// 避免多选下拉框太长，限制高度+滚动
.select-max-height-scroll {
  .ant-select-selector {
    max-height: 200px;
    overflow-y: scroll;
  }
}

.fix-bottom-bar {
  position: fixed;
  bottom: 0px;
  left: 0px;
  padding: 10px;
  background: rgb(255, 255, 255);
  width: 100%;
  border-top: 1px solid rgb(221, 221, 221);
  z-index: 2;
  text-align: center;
}

.white-bg {
  background-color: #FFFFFF !important;
}
