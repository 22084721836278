.ant-custom-table {
  width: 100%;
  table {
    table-layout: auto;
    width: 100%;
  }
  .ant-custom-table-thead {
    background: #f8f8fc;
    > tr > th {
      padding: 12px 8px;
      text-align: left;
      font-weight: bold;
      overflow-wrap: break-word;
      color: #262626;
    }
  }
  .ant-custom-table-tbody {
    > tr > td {
      padding: 6px 8px;
      text-align: left;
      vertical-align: middle;
      .ant-input-affix-wrapper {
        padding: 0 11px;
        .ant-input {
          height: 30px;
        }
        .ant-input-suffix {
          line-height: 30px;
        }
      }
    }
  }
}
