@import './theme.less';

.portal-wrapper {
    position: relative;

    .portal-container {
        margin-top: 60px;

        .portal-container-page {
            padding: 12px;
            background-color: #fff;
        }
    }

    .portal-spin {
        min-height: 600px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}

.portal-header {
    height: 60px !important;
    line-height: 60px !important;
    padding: 0 32px !important;
    background-color: @header-background-color !important;
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.06);
    position: fixed;
    display: flex;
    top: 0;
    width: 100%;
    z-index: 1000;
    justify-content: space-between;

    .header-left {
        display: flex;
        align-items: center;

        .header-logo {
            margin-right: 32px;
            display: flex;

            img {
                display: block;
                height: 40px;
            }
        }
    }

    .header-right {
        display: flex;

        .header-helper {
            display: flex;
            align-items: center;
            overflow: hidden;

            .helper-list {
                display: inline-block;

                li {
                    padding: 0 8px;
                    display: inline-block;
                    font-size: 12px;
                    color: @text-color-primary;

                    a {
                        font-size: 12px;
                        color: @text-color-primary;
                    }
                }

                .helper-list-icon {
                    font-size: 18px;
                    color: @text-color ;
                }
            }

            .helper-avatar {
                line-height: initial;
                display: inline-block;

                .avatar-img {
                    width: 25px;
                    height: 25px;
                    border: 1px solid @border-color-base;
                }
            }
        }
    }

    .ant-badge {
        color: @text-color-primary;
    }
}

.header-product {
    display: flex;
    align-items: center;

    .ant-select:not(.ant-select-customize-input) .ant-select-selector {
        background-color: none;
        padding: 0 20px 0 5px;
        min-width: 200px;
        height: 34px;
        border: 1px solid @border-color-base;
        box-shadow: 0px 0px 8px @box-shadow-base;
        transition: .5s;
    }

    .ant-select {
        width: 100%;

        .ant-select-selection {
            border-radius: 20px;
        }

        .ant-select-selection-search {
            color: @text-color;
        }
    }

    .ant-select-arrow {
        color: #aaaaaa;
    }

    .product-select-item {
        display: flex;
        align-items: center;
        color: @text-color;
        line-height: 32px;

        img {
            width: 24px;
            height: 24px;
            margin-right: 5px;
            border-radius: 5px;
        }
    }

    .ant-select-dropdown {
        padding: 16px 0 !important;
        border-radius: 8px !important;
        min-width: 180px !important;

        .product-select-item {
            height: 40px;
            font-size: 14px;
            color: @text-color;
        }
    }
}

.header-menu {
    min-width: 500px;

    .ant-menu {
        .ug-menu-arrow-img {
            position: relative;
            width: 10px;
            top: -2px;
            left: 2px;
        }

        margin: 0 20px !important;
        line-height: inherit;
        background: none;
        height: inherit !important;
        border-bottom: 0 none !important;

        a {
            color: @text-color !important;
        }

        .ant-menu-overflow-item {
            padding: 0 16px !important;
            font-size: 14px;
            color: @text-color-primary !important;
            height: inherit !important;
            word-break: break-all !important;
            cursor: pointer;
        }

        .ant-menu-item-selected,
        .ant-menu-item-active,
        .ant-menu-submenu-selected,
        .ant-menu-submenu-active {
            &:after {
                right: 16px !important;
                left: 16px !important;
                border-bottom: 3px solid @primary-color !important;
            }

            .ant-menu-title-content a {
                color: @primary-color !important;
            }
        }

        .ant-menu-submenu {
            .ant-dropdown-trigger {
                padding: 0 !important;
                margin: 0 !important;
                display: block;
                height: inherit;
                color: @text-color !important;

                .anticon.anticon-down {
                    font-size: 12px;
                    margin-left: 3px;
                    color: #AAAAAA;
                    bottom: -1px;
                    position: relative;
                }
            }

            .ant-dropdown-menu {
                margin-top: -4px;
                padding: 20px;
                border-radius: 8px;
                font-size: 14px;
                overflow: hidden;

                .ant-dropdown-menu-title-content {
                    a {
                        color: #666666 !important;
                        font-size: 14px;
                    }
                }

                .ant-dropdown-menu-item,
                .ant-dropdown-menu-item-active {
                    padding: 8px 0px;
                    text-align: left;
                }

                .ant-dropdown-menu-item-active {
                    a {
                        color: @primary-color !important;
                    }
                }

                .ant-dropdown-menu-icon {
                    height: 20px;
                    width: 20px;
                    vertical-align: middle;
                }

                .ant-dropdown-menu-icon-custom {
                    height: 16px;
                    width: 16px;
                    position: relative;
                    top: -1px;
                }
            }

            .ant-dropdown-menu-custom-group {
                display: inline-table;
                margin-right: 20px;

                .ant-dropdown-menu-item-group-title {
                    color: @text-color;
                    font-weight: 600;
                    padding-left: 0;
                    padding-right: 0;
                }

                .ant-dropdown-menu-custom-title {
                    display: flex;
                    align-items: center;
                    cursor: default;
                }

                .ant-dropdown-menu-item-group-list {
                    .ant-dropdown-menu-item {
                        padding-left: 25px;
                    }
                }
            }
        }

        .ant-menu-submenu-selected,
        .ant-menu-submenu-active {
            .ant-dropdown-trigger {
                color: @primary-color !important;
            }
        }
    }
}

.helper-popover {
    top: 40px !important;

    .ant-popover-arrow {
        display: none;
    }

    .ant-popover-inner {
        border-radius: 8px;
    }

    .ant-popover-inner-content {
        padding: 0;
    }
}

//hover头像用户卡片
.usercard {
    min-width: 220px;

    .card-top {
        padding: 16px 20px;
        border-bottom: 1px solid @border-color-base;
        display: flex;
        justify-content: center;
        align-items: center;

        .avatar {
            width: 45px;
            height: 45px;
            border: 1px solid @border-color-base;
            margin-right: 10px;
        }
    }

    .card-bottom {
        padding: 16px 20px;
        text-align: center;

        a {
            font-size: 14px;
            color: #333333;
        }
    }
}

@media only screen and (max-width: 1280px) {
    .portal-header {
        .header-left {
            .header-logo {
                margin-right: 4px;
            }
        }

        .header-right {
            .header-helper .helper-list .helper-list-icon {
                font-size: 12px;
            }
        }
    }

    .header-product {
        .ant-select:not(.ant-select-customize-input) .ant-select-selector {
            min-width: 100px;
            padding: 0 18px 0 5px;

            .ant-select-selection-item {
                padding-right: 2px;

                span {
                    font-size: 11px;
                }
            }
        }

        .ant-select-arrow {
            font-size: 9px;
        }

        .product-select-item {
            line-height: 34px;
        }
    }

    .header-menu {
        .ant-menu {
            min-width: 600px;

            .ant-menu-overflow-item {
                padding: 0 8px !important;
                font-size: 11px;
            }
        }
    }
}

@media only screen and (max-width: 720px) {
    .portal-header {
        padding: 0 4px !important;

        .header-left {
            .header-logo {
                display: none;
            }
        }

        .header-right {
            display: none;
        }
    }

    .header-menu {
        .ant-menu {
            margin: 0 6px !important;
            min-width: 500px;

            .ug-menu-arrow-img {
                width: 10px;
                top: -2px;
                left: 2px;
            }

            .ant-menu-overflow-item {
                padding: 0 2px !important;
                font-size: 11px;
            }
        }
    }
}
